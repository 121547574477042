export type GigyaUserRecord = {
	profileSchema: {
		photoURL?: string | string[];
		oidcData: {
			address: {
				locality?: string | string[];
				formatted?: string | string[];
				country?: string | string[];
				region?: string | string[];
				postal_code?: string | string[];
				street_address?: string | string[];
			} | Array<{
				locality?: string | string[];
				formatted?: string | string[];
				country?: string | string[];
				region?: string | string[];
				postal_code?: string | string[];
				street_address?: string | string[];
			}>;
			website?: string | string[];
			phone_number_verified?: string | string[];
			phone_number?: string | string[];
			locale?: string | string[];
			updated_at?: string | string[];
			name?: string | string[];
			email_verified?: string | string[];
			middle_name?: string | string[];
			zoneinfo?: string | string[];
		} | Array<{
			address: {
				locality?: string | string[];
				formatted?: string | string[];
				country?: string | string[];
				region?: string | string[];
				postal_code?: string | string[];
				street_address?: string | string[];
			} | Array<{
				locality?: string | string[];
				formatted?: string | string[];
				country?: string | string[];
				region?: string | string[];
				postal_code?: string | string[];
				street_address?: string | string[];
			}>;
			website?: string | string[];
			phone_number_verified?: string | string[];
			phone_number?: string | string[];
			locale?: string | string[];
			updated_at?: string | string[];
			name?: string | string[];
			email_verified?: string | string[];
			middle_name?: string | string[];
			zoneinfo?: string | string[];
		}>;
		favorites: {
			activities: {
				category?: string | string[];
				id?: string | string[];
				name?: string | string[];
			} | Array<{
				category?: string | string[];
				id?: string | string[];
				name?: string | string[];
			}>;
			movies: {
				name?: string | string[];
				category?: string | string[];
				id?: string | string[];
			} | Array<{
				name?: string | string[];
				category?: string | string[];
				id?: string | string[];
			}>;
			music: {
				name?: string | string[];
				id?: string | string[];
				category?: string | string[];
			} | Array<{
				name?: string | string[];
				id?: string | string[];
				category?: string | string[];
			}>;
			television: {
				name?: string | string[];
				id?: string | string[];
				category?: string | string[];
			} | Array<{
				name?: string | string[];
				id?: string | string[];
				category?: string | string[];
			}>;
			interests: {
				category?: string | string[];
				id?: string | string[];
				name?: string | string[];
			} | Array<{
				category?: string | string[];
				id?: string | string[];
				name?: string | string[];
			}>;
			books: {
				category?: string | string[];
				name?: string | string[];
				id?: string | string[];
			} | Array<{
				category?: string | string[];
				name?: string | string[];
				id?: string | string[];
			}>;
		} | Array<{
			activities: {
				category?: string | string[];
				id?: string | string[];
				name?: string | string[];
			} | Array<{
				category?: string | string[];
				id?: string | string[];
				name?: string | string[];
			}>;
			movies: {
				name?: string | string[];
				category?: string | string[];
				id?: string | string[];
			} | Array<{
				name?: string | string[];
				category?: string | string[];
				id?: string | string[];
			}>;
			music: {
				name?: string | string[];
				id?: string | string[];
				category?: string | string[];
			} | Array<{
				name?: string | string[];
				id?: string | string[];
				category?: string | string[];
			}>;
			television: {
				name?: string | string[];
				id?: string | string[];
				category?: string | string[];
			} | Array<{
				name?: string | string[];
				id?: string | string[];
				category?: string | string[];
			}>;
			interests: {
				category?: string | string[];
				id?: string | string[];
				name?: string | string[];
			} | Array<{
				category?: string | string[];
				id?: string | string[];
				name?: string | string[];
			}>;
			books: {
				category?: string | string[];
				name?: string | string[];
				id?: string | string[];
			} | Array<{
				category?: string | string[];
				name?: string | string[];
				id?: string | string[];
			}>;
		}>;
		certifications: {
			number?: string | string[];
			endDate?: Date | Date[];
			name?: string | string[];
			startDate?: Date | Date[];
			authority?: string | string[];
		} | Array<{
			number?: string | string[];
			endDate?: Date | Date[];
			name?: string | string[];
			startDate?: Date | Date[];
			authority?: string | string[];
		}>;
		religion?: string | string[];
		likes: {
			timestamp?: number | number[];
			id?: string | string[];
			name?: string | string[];
			time?: Date | Date[];
			category?: string | string[];
		} | Array<{
			timestamp?: number | number[];
			id?: string | string[];
			name?: string | string[];
			time?: Date | Date[];
			category?: string | string[];
		}>;
		birthYear?: number | number[];
		phones: {
			type?: string | string[];
			number?: string | string[];
			default?: string | string[];
		} | Array<{
			type?: string | string[];
			number?: string | string[];
			default?: string | string[];
		}>;
		patents: {
			date?: Date | Date[];
			status?: string | string[];
			url?: string | string[];
			office?: string | string[];
			summary?: string | string[];
			number?: string | string[];
			title?: string | string[];
		} | Array<{
			date?: Date | Date[];
			status?: string | string[];
			url?: string | string[];
			office?: string | string[];
			summary?: string | string[];
			number?: string | string[];
			title?: string | string[];
		}>;
		languages?: string | string[];
		work: {
			startDate?: Date | Date[];
			description?: string | string[];
			title?: string | string[];
			industry?: string | string[];
			location?: string | string[];
			endDate?: Date | Date[];
			company?: string | string[];
			companyID?: string | string[];
			companySize?: number | number[];
			isCurrent?: boolean | boolean[];
		} | Array<{
			startDate?: Date | Date[];
			description?: string | string[];
			title?: string | string[];
			industry?: string | string[];
			location?: string | string[];
			endDate?: Date | Date[];
			company?: string | string[];
			companyID?: string | string[];
			companySize?: number | number[];
			isCurrent?: boolean | boolean[];
		}>;
		industry?: string | string[];
		firstName?: string | string[];
		publications: {
			publisher?: string | string[];
			url?: string | string[];
			title?: string | string[];
			summary?: string | string[];
			date?: Date | Date[];
		} | Array<{
			publisher?: string | string[];
			url?: string | string[];
			title?: string | string[];
			summary?: string | string[];
			date?: Date | Date[];
		}>;
		city?: string | string[];
		verified?: string | string[];
		gender?: string | string[];
		hometown?: string | string[];
		followersCount?: number | number[];
		birthDay?: number | number[];
		interests?: string | string[];
		lastName?: string | string[];
		locale?: string | string[];
		skills: {
			level?: string | string[];
			skill?: string | string[];
			years?: number | number[];
		} | Array<{
			level?: string | string[];
			skill?: string | string[];
			years?: number | number[];
		}>;
		relationshipStatus?: string | string[];
		name?: string | string[];
		education: {
			endYear?: number | number[];
			startYear?: number | number[];
			school?: string | string[];
			schoolType?: string | string[];
			fieldOfStudy?: string | string[];
			degree?: string | string[];
		} | Array<{
			endYear?: number | number[];
			startYear?: number | number[];
			school?: string | string[];
			schoolType?: string | string[];
			fieldOfStudy?: string | string[];
			degree?: string | string[];
		}>;
		honors?: string | string[];
		age?: number | number[];
		bio?: string | string[];
		state?: string | string[];
		lastLoginLocation: {
			coordinates: {
				lat?: number | number[];
				lon?: number | number[];
			} | Array<{
				lat?: number | number[];
				lon?: number | number[];
			}>;
			state?: string | string[];
			city?: string | string[];
			country?: string | string[];
		} | Array<{
			coordinates: {
				lat?: number | number[];
				lon?: number | number[];
			} | Array<{
				lat?: number | number[];
				lon?: number | number[];
			}>;
			state?: string | string[];
			city?: string | string[];
			country?: string | string[];
		}>;
		professionalHeadline?: string | string[];
		email: string | string[];
		politicalView?: string | string[];
		educationLevel?: string | string[];
		timezone?: string | string[];
		address?: string | string[];
		proxyemail?: string | string[];
		followingCount?: number | number[];
		profileURL?: string | string[];
		thumbnailURL?: string | string[];
		specialities?: string | string[];
		interestedIn?: string | string[];
		birthMonth?: number | number[];
		nickname?: string | string[];
		username?: string | string[];
		zip?: string | string[];
		country?: string | string[];
		activities?: string | string[];
	};
	dataSchema: {
		practiceAddressLine1?: string | string[];
		practiceAddressLine2?: string | string[];
		accept_marketing_terms_conditions?: Date | Date[];
		primaryPhoneNumber?: string | string[];
		roleCode?: string | string[];
		currentSchooName?: string | string[];
		hillsAccountNumber?: string | string[];
		officePhoneNumber?: string | string[];
		roleId?: string | string[];
		ccCreationDate?: Date | Date[];
		isGSFUser?: boolean | boolean[];
		mobileNumber?: string | string[];
		wholesaler?: string | string[];
		titleCode?: string | string[];
		email?: string | string[];
		isCLPUser?: boolean | boolean[];
		currentSchoolName?: string | string[];
		isHroCommerceAccountActive?: boolean | boolean[];
		countryCode_ISO?: string | string[];
		practicePostalCode?: string | string[];
		SF_StoreSoldTo?: string | string[];
		schools?: string | string[];
		birthDate?: string | string[];
		subscribe?: boolean | boolean[];
		qualification?: string | string[];
		tax_invoice_number?: string | string[];
		countryCode?: string | string[];
		tax_invoice_selection?: string | string[];
		practiceNumber?: string | string[];
		practiceName?: string | string[];
		graduationYear?: string | string[];
		phoneNumber?: string | string[];
		prefix?: string | string[];
		profStateLicense?: string | string[];
		isHVSAccount?: boolean | boolean[];
		contactPersonNumberCRM?: string | string[];
		isVetRepresentative?: boolean | boolean[];
		practiceCity?: string | string[];
		terms?: boolean | boolean[];
		beginYearStudy?: string | string[];
		invoiceNumber?: string | string[];
		contactPersonNumberERP?: string | string[];
		SF_PreferedShipTo?: string | string[];
		professionCode?: string | string[];
		primaryFaxNumber?: string | string[];
		lineAccount?: string | string[];
		salesOrg?: string | string[];
		professionCategory?: string | string[];
		qualificationCode?: string | string[];
		yearsOfStudy?: string | string[];
		profNbr?: string | string[];
		payoutOption?: string | string[];
		roleType?: string | string[];
		hmAccountNumber?: string | string[];
		gradYear?: string | string[];
		clinicLocation?: string | string[];
	};
	subscriptionsSchema: {
		uscrmOptIn?: undefined;
		hillsPhoneOptIn?: undefined;
		globalEmailOptin?: undefined;
		globalSMSOptin?: undefined;
		hroOptIn?: undefined;
		globalEmailAndSMSOptin?: undefined;
	};
	preferencesSchema: {
		terms: {
			hillsTermsOfUse: undefined;
			hillsPrivacyPolicy?: undefined;
			hillsWebshopTermsOfUse?: undefined;
			globalTermsOfService?: undefined;
			hills4meTerms?: undefined;
			hillsGSFTermsOfUse: undefined;
		};
		privacy: {
			hillsPrivacyPolicy: undefined;
		};
	};
};

export enum GigyaGroupModels {
	ClinicModel = 'ClinicModelAsia',
}

export type GigyaGroupSchemas = {
	ClinicModelAsia: {
		relationshipDataSchema: {
			role?: string | string[];
			comment?: string | string[];
			status?: string | string[];
			permissions?: string | string[];
			nickname?: string | string[];
		};
		groupDataSchema: {
			streetAddress: string | string[];
			billTo?: string | string[];
			softClosed?: boolean | boolean[];
			blockedClinic?: boolean | boolean[];
			billingCity?: string | string[];
			shipTo?: string | string[];
			clinicName: string | string[];
			directClinic?: boolean | boolean[];
			isStaffFeedingClinic?: boolean | boolean[];
			SFHomeDeliveryOnly?: boolean | boolean[];
			altID?: string | string[];
			soldTo?: string | string[];
			salesOrg?: string | string[];
			country: string | string[];
			archived?: boolean | boolean[];
			removedUsers?: string | string[];
			payer?: string | string[];
			zip: string | string[];
			salesTerritory?: string | string[];
			customerClass?: string | string[];
			customerPlanGroup?: string | string[];
			city: string | string[];
			soldToClinicName?: string | string[];
			clinicType?: string | string[];
			billingZip?: string | string[];
			customerGroup?: string | string[];
			approvedEmails?: string | string[];
			customerPriceProcedure?: string | string[];
			accountType?: string | string[];
			paymentTerm?: string | string[];
			companyCode?: string | string[];
			state: string | string[];
			salesZone?: string | string[];
			salesRegion?: string | string[];
			priceGroup?: string | string[];
			industry?: string | string[];
		};
	};
};
